export const initalState = {
  content: {
    en: {
      title: "",
      message: "",
    },
    ps: {
      title: "",
      message: "",
    },
    fa: {
      title: "",
      message: "",
    },
  },
  media: {en: {}, ps: {}, fa: {}},
  filters: {
    age: [],
    language_id: [],
    gender: "",
    gradeId: [],
    active_enrollment_course_id: "",
    inactive_since_lesson_id: "",
    country: "",
    country_state: [],
    inactive_since_days: "",
    active_since_days: "",
    inactive_since_date: "",
    active_since_date: "",
  },
  schedule: {
    type: "",
    start_date: "",
    start_time: "",
    end_date: "",
    frequency: 1,
    duration: "day",
    weekdays: null,
    ends: "never",
    state: null,
  },
  appliedSchedule: {},
  copied: false,
};

export const reducer = (state, action) => {
  let filters = { ...state.filters };
  let schedule = { ...state.schedule };
  let appliedSchedule = { ...state.appliedSchedule };

  switch (action.type) {
    //Content English
    case "setTitleEn":
      return {
        ...state,
        content: {
          ...state.content,
          en: { ...state.content.en, title: action.payload },
        },
      };
    case "setMessageEn":
      return {
        ...state,
        content: {
          ...state.content,
          en: { ...state.content.en, message: action.payload },
        },
      };
    //Content Pahto
    case "setTitlePs":
      return {
        ...state,
        content: {
          ...state.content,
          ps: { ...state.content.ps, title: action.payload },
        },
      };
    case "setMessagePs":
      return {
        ...state,
        content: {
          ...state.content,
          ps: { ...state.content.ps, message: action.payload },
        },
      };
    //Content Dari
    case "setTitleFa":
      return {
        ...state,
        content: {
          ...state.content,
          fa: { ...state.content.fa, title: action.payload },
        },
      };
    case "setMessageFa":
      return {
        ...state,
        content: {
          ...state.content,
          fa: { ...state.content.fa, message: action.payload },
        },
      };
    case "setContent":
      return {
        ...state,
        content: { ...action.payload },
      };
    //Filters
    case "setAge":
      return { ...state, filters: { ...filters, age: action.payload } };
    case "setGender":
      return { ...state, filters: { ...filters, gender: action.payload } };
    case "setCountry":
      return { ...state, filters: { ...filters, country: action.payload } };
    case "setActiveEnrollmentCourseId":
      return { ...state, filters: { ...filters, active_enrollment_course_id: action.payload } };
    case "setInactiveSinceLessonId":
      return { ...state, filters: { ...filters, inactive_since_lesson_id: action.payload } };
    case "setProvinceIds":
      return {
        ...state,
        filters: { ...filters, country_state: action.payload },
      };
    case "setGradeIds":
      return { ...state, filters: { ...filters, gradeId: action.payload } };
    case "setLanguageIds":
      return { ...state, filters: { ...filters, language_id: action.payload } };
    case "setInactiveDays":
      return {
        ...state,
        filters: { ...filters, inactive_since_days: action.payload },
      };
    case "setActiveDays":
      return {
        ...state,
        filters: { ...filters, active_since_days: action.payload },
      };
    case "setInactiveSinceDate":
      return {
        ...state,
        filters: { ...filters, inactive_since_date: action.payload },
      };
    case "setActiveSinceDate":
      return {
        ...state,
        filters: { ...filters, active_since_date: action.payload },
      };
    //Schedule
    case "setScheduleType":
      return { ...state, schedule: { ...schedule, type: action.payload } };
    case "setScheduleState":
        return { ...state, schedule: { ...schedule, state: action.payload } };
    case "setStartDate":
      return {
        ...state,
        schedule: { ...schedule, start_date: action.payload },
      };
    case "setStartTime":
      return {
        ...state,
        schedule: { ...schedule, start_time: action.payload },
      };
    // Applied Schedule
    case "setEndDate":
      return { ...state, appliedSchedule: { ...appliedSchedule, end_date: action.payload } };
    case "setFrequency":
      return { ...state, appliedSchedule: { ...appliedSchedule, frequency: action.payload } };
    case "setDuration":
      return { ...state, appliedSchedule: { ...appliedSchedule, duration: action.payload, weekdays: action.payload == 'week' ? ["1"] : null } };
    case "setWeekDays":
      return { ...state, appliedSchedule: { ...appliedSchedule, weekdays: action.payload } };
    case "setEnds":
      return { ...state, appliedSchedule: { ...appliedSchedule, ends: action.payload } };
    case "copyState":
      return {
        ...state,
        content: { ...action.payload.content },
        media: { ...action.payload.media },
        filters: {
          ...action.payload.filters,
          gradeId: action?.payload?.filters?.grade_id
        },
        schedule: { 
          ...action.payload.schedule,
          start_time: action?.payload?.schedule?.start_date
        },
      };
    case "clearRepeatFilters":
      return {
        ...state,
        appliedSchedule: {
          end_date: "",
          frequency: 1,
          duration: "day",
          weekdays: [],
          ends: "never",
        },
      };
    case "setScheduleFilters": {
      return {
        ...state,
        schedule: { ...state?.schedule, ...state?.appliedSchedule },
      };
    }
    case "setAppliedScheduleFilters":
      // eslint-disable-next-line
      const { type, start_date, start_time, ...repeatFormSchedule } = state?.schedule;
      return { ...state, appliedSchedule: {...repeatFormSchedule, ends: repeatFormSchedule?.end_date ? "on" : "never" }};
    case "clearOccuranceFilters":
      return {
        ...state,
        filters: {
          ...state.filters,
          inactive_since_days: "",
          active_since_days: "",
          inactiveEndDate: "",
        },
        schedule: {
          ...state.schedule,
          start_date: "",
          start_time: "",
          end_date: "",
          frequency: 1,
          duration: "day",
          weekdays: [],
          ends: "never",
        },
        appliedSchedule: {}
      };
    case "setCopied":
      return { ...state, copied: action.payload };
    case "setMedia":
      return { ...state, media: {...state.media, ...action.payload}};
    default:
      return state;
  }
};
