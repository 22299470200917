import { ordinalize } from "../../utils/ordinalize";

export const newCourse = () => ({
  id: null,
  attributes: {
    title: "",
    description: "",
    published: false,
    grade_id: null,
  },
});

export const getGradeList = (course) => {
  const { type } = course?.attributes ?? {};
  const filterByField = type === "OtherType" ? "school_levels" : "grades";
  return course?.attributes?.[filterByField]
    ?.map((gradeId) => ordinalize(gradeId))
    ?.join(", ");
};
