import React, { Fragment, useState } from "react";
import PageTitle from "../../components/page_title";
import { Table, Button } from "rsuite";
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";
import CertificateTemplateForm from "./form";
import DeleteConfirmationModal from "../../components/delete_confirmation_modal";
import axios from "axios";
import { getAuthenticityToken } from "../../utils/authenticity_token";
import { format_date_without_time_zone } from "../../utils/date_formatter";

const { Column, Cell: OriginalCell, HeaderCell: OriginalHeaderCell } = Table;

const HeaderCell = (props) => (
  <OriginalHeaderCell {...props} style={{ padding: 10 }} />
);

const Cell = (propos) => <OriginalCell {...propos} style={{ padding: 10 }} />;

const Index = () => {
  const {
    data = [],
    isLoading,
    mutate,
  } = useSWR(
    {
      url: "/api/v0/certificate_templates",
    },
    fetcher,
  );

  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [certificateTemplateToEdit, setCertificateTemplateToEdit] =
    useState(null);
  const [certificateTemplateToDelete, setCertificateTemplateToDelete] =
    useState(null);

  const onClickView = (rowData) =>
    window.open(`/certificates/preview?template_id=${rowData["id"]}`, "_blank");
  const onClickEdit = (rowData) => {
    setCertificateTemplateToEdit(rowData);
    setShowAddTemplate(true);
  };
  const onClickDelete = (rowData) => setCertificateTemplateToDelete(rowData);

  return (
    <div className="container">
      <div className="container">
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <PageTitle title={"Certificate Templates"} />
          <Button
            color="green"
            appearance="primary"
            onClick={() => setShowAddTemplate(true)}
          >
            <i className="fa fa-plus me-2"></i>
            Add Template
          </Button>
        </div>

        <Fragment>
          <Table
            loading={isLoading}
            height={500}
            width={"100wv"}
            data={data?.data || []}
          >
            <Column width={100}>
              <HeaderCell>No</HeaderCell>
              <Cell>{(rowData, rowIndex) => <div>{rowIndex + 1}</div>}</Cell>
            </Column>
            <Column width={100} flexGrow={2}>
              <HeaderCell>Title</HeaderCell>
              <Cell dataKey="attributes.title" />
            </Column>
            <Column width={100} flexGrow={3}>
              <HeaderCell>Template</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div>{rowData["attributes"]["template"]["en"]}</div>
                )}
              </Cell>
            </Column>
            <Column width={100} flexGrow={1}>
              <HeaderCell>Created At</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div>
                    {format_date_without_time_zone(
                      rowData?.attributes.created_at,
                    )}
                  </div>
                )}
              </Cell>
            </Column>
            <Column width={100} flexGrow={1}>
              <HeaderCell>Updated At</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div>
                    {format_date_without_time_zone(
                      rowData?.attributes.updated_at,
                    )}
                  </div>
                )}
              </Cell>
            </Column>
            <Column width={200} align="center" flexGrow={1}>
              <HeaderCell>Actions</HeaderCell>
              <Cell style={{ padding: "6px" }}>
                {(rowData) => {
                  return (
                    <div className="light-txt px-2 py-2">
                      <i
                        role="button"
                        tabIndex={0}
                        className="fa-solid fa-eye me-2"
                        onKeyDown={() => onClickView(rowData)}
                        onClick={() => onClickView(rowData)}
                      ></i>
                      <i
                        role="button"
                        tabIndex={0}
                        className="fa-solid fa-pencil me-2"
                        onKeyDown={() => onClickEdit(rowData)}
                        onClick={() => onClickEdit(rowData)}
                      />
                      <i
                        role="button"
                        tabIndex={0}
                        className="fa-solid fa-trash me-2"
                        onKeyDown={() => onClickDelete(rowData)}
                        onClick={() => onClickDelete(rowData)}
                      ></i>
                    </div>
                  );
                }}
              </Cell>
            </Column>
          </Table>
          {showAddTemplate && (
            <CertificateTemplateForm
              open={showAddTemplate}
              onConfirm={() => {
                mutate();
                setShowAddTemplate(false);
                setCertificateTemplateToEdit(null);
              }}
              onClose={() => {
                setShowAddTemplate(false);
                setCertificateTemplateToEdit(null);
              }}
              template={certificateTemplateToEdit}
            />
          )}
          {!!certificateTemplateToDelete && (
            <DeleteConfirmationModal
              open={!!certificateTemplateToDelete}
              onClose={() => setCertificateTemplateToDelete(null)}
              onConfirm={() => {
                axios({
                  method: "DELETE",
                  url: `/api/v0/certificate_templates/${certificateTemplateToDelete.id}`,
                  headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": getAuthenticityToken(),
                  },
                })
                  .then(() => {
                    setCertificateTemplateToDelete(null);
                    mutate();
                    return true;
                  })
                  .catch((error) => {
                    console.log(error);
                    setCertificateTemplateToDelete(null);
                  });
              }}
            />
          )}
        </Fragment>
      </div>
    </div>
  );
};

export default Index;
