import React, { useState } from "react";
import { Outlet, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import PageTitle from "../../components/page_title";
import SubpageNavbar from "../../components/subpage_navbar";
import List from "./list";
import EditForm from "./form";

const items = [
  {
    to: "once",
    label: "One Time",
  },
  {
    to: "repeat",
    label: "Repeated",
  },
  {
    to: "state",
    label: "State Based",
  },
];

const newReminder = () => ({
  id: null,
});

const Wrapper = ({ setReminderToEdit }) => {
  return (
    <div className="container">
      <div className="row align-items-center mb-3">
        <div className="col-md-4">
          <PageTitle title={"Reminders"} />
        </div>
        <div className="col-md-8">
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => setReminderToEdit(newReminder())}
              color="green"
              appearance="primary"
            >
              <i className="fa fa-plus me-2"></i>Create New Reminder
            </Button>
          </div>
        </div>
      </div>
      <div className="shadow-sm bg-white radius-5 tab-sec">
        <div className="top">
          {items.length > 1 && <SubpageNavbar items={items} />}
        </div>
        <div className="inner">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const Index = () => {
  const navigate = useNavigate();
  const [pageName, setPageName] = useState("once");
  const [reminderToEdit, setReminderToEdit] = useState(null);

  return (
    <div>
      {reminderToEdit ? (
        <EditForm
          open={!!reminderToEdit}
          id={reminderToEdit?.id}
          editable={reminderToEdit?.attributes?.editable}
          page={reminderToEdit?.page}
          pageName={pageName}
          onClose={() => {
            if (window.location.href.indexOf("content") > -1) {
              navigate(`/reminders/schedule`);
            } else {
              navigate(
                reminderToEdit.page
                  ? `/reminders/${pageName}?page=${reminderToEdit.page}`
                  : `/reminders/${pageName}`,
              );
              setReminderToEdit(null);
            }
          }}
        />
      ) : (
        <Routes>
          <Route
            path="*"
            element={<Wrapper setReminderToEdit={setReminderToEdit} />}
          >
            <Route
              path="once"
              element={
                <List
                  type="once"
                  setPageName={setPageName}
                  setReminderToEdit={setReminderToEdit}
                />
              }
            />
            <Route
              path="repeat"
              element={
                <List
                  type="repeat"
                  setPageName={setPageName}
                  setReminderToEdit={setReminderToEdit}
                />
              }
            />
            <Route
              path="state"
              element={
                <List
                  type="state"
                  setPageName={setPageName}
                  setReminderToEdit={setReminderToEdit}
                />
              }
            />
            <Route path="*" element={<Navigate to="once" />} />
          </Route>
        </Routes>
      )}
    </div>
  );
};

export default Index;
