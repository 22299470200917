import React, { Fragment, useState } from "react";
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";
import { Table, Button, Avatar } from "rsuite";
import Form from "./form";
import axios from "axios";
import { getAuthenticityToken } from "../../utils/authenticity_token";
import DeleteConfirmationModal from "../../components/delete_confirmation_modal";
import PageTitle from "../../components/page_title";

const { Column, Cell: OriginalCell, HeaderCell: OriginalHeaderCell } = Table;

const HeaderCell = (props) => (
  <OriginalHeaderCell {...props} style={{ padding: 10 }} />
);

const Cell = (propos) => <OriginalCell {...propos} style={{ padding: 10 }} />;

const newProjectMedia = () => ({
  id: null,
  attributes: {
    key: "",
    url: null,
    filename: null,
  },
});

const Index = () => {
  const {
    data = [],
    isLoading,
    mutate,
  } = useSWR(
    {
      url: "/api/v0/project_media",
    },
    fetcher,
  );

  const [projectMediaToEdit, setProjectMediaToEdit] = useState(null);
  const [projectMediaToDelete, setProjectMediaToDelete] = useState(null);
  const [editErrors, setEditErrors] = useState({});
  return (
    <div className="container">
      <div className='mb-3 d-flex justify-content-between align-items-center'>
        <PageTitle title={"Project Media"} />  
        <Button
          color="green"
          appearance="primary"
          onClick={() => setProjectMediaToEdit(newProjectMedia())}
        >
          <i className="fa fa-plus me-2"></i>
          Add Project Media
        </Button>      
      </div>

    <div className="shadow-sm bg-white radius-5 overflow-hidden">
      <div className="inner">
        <Fragment>
          <Table
            loading={isLoading}
            height={500}
            width={"100wv"}
            data={data?.data || []}
          >
            <Column width={100}>
              <HeaderCell>No</HeaderCell>
              <Cell>{(rowData, rowIndex) => <div>{rowIndex + 1}</div>}</Cell>
            </Column>
            <Column width={100} flexGrow={3}>
              <HeaderCell>Key</HeaderCell>
              <Cell dataKey="attributes.key" />
            </Column>
            <Column width={100} flexGrow={3}>
              <HeaderCell>Media</HeaderCell>
              <Cell>
                {(rowData) => (
                  <Avatar
                    src={rowData["attributes"]["url"]}
                    size="lg"
                    alt="project-media"
                  />
                )}
              </Cell>
            </Column>
            <Column width={200} align="center" flexGrow={3}>
              <HeaderCell>Actions</HeaderCell>
              <Cell>
                {(rowData) => {
                  if (rowData.children) {
                    return null;
                  }
                  return (
                    <div>
                      <i className="light-txt py-2 fa-solid fa-trash" onClick={() => setProjectMediaToDelete(rowData)}></i>                      
                    </div>
                  );
                }}
              </Cell>
            </Column>
          </Table>
          {!!projectMediaToEdit && (
            <Form
              open={!!projectMediaToEdit}
              projectMedia={projectMediaToEdit}
              errors={editErrors}
              onConfirm={({ key, url, filename }) => {
                const { id } = projectMediaToEdit;
                const isNew = !id;
                if (isNew) {
                  const post_url = "/api/v0/project_media";
                  const data = {
                    key,
                    url,
                    filename,
                  };
                  axios({
                    method: "POST",
                    url: post_url,
                    headers: {
                      "Content-Type": "application/json",
                      "X-CSRF-Token": getAuthenticityToken(),
                    },
                    data,
                  })
                    .then((response) => {
                      mutate();
                      setProjectMediaToEdit(null);
                      setEditErrors({});
                    })
                    .catch((error) => {
                      console.log(error);
                      setEditErrors(error.response.data.errors);
                    });
                }
              }}
              onClose={() => {
                setProjectMediaToEdit(null);
                setEditErrors({});
              }}
            />
          )}
          {!!projectMediaToDelete && (
            <DeleteConfirmationModal
              open={!!projectMediaToDelete}
              onClose={() => setProjectMediaToDelete(null)}
              onConfirm={() => {
                axios({
                  method: "DELETE",
                  url: `/api/v0/project_media/${projectMediaToDelete.id}`,
                  headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": getAuthenticityToken(),
                  },
                }).then((e) => {
                  setProjectMediaToDelete(null);
                  mutate();
                });
              }}
            />
          )}
        </Fragment>
      </div>
    </div>
  </div>
  );
};

export default Index;
