/**
 *
 * @param obj
 */
export function replaceEmptyValues(obj) {
  for (let key in obj) {
    if (obj[key] === null || obj[key] === "") {
      obj[key] = "--";
    } else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      replaceEmptyValues(obj[key]);
    }
  }
}

export const isToday = (value) => {
  return (
    value[0].toDateString() === new Date().toDateString() &&
    value[1].toDateString() === new Date().toDateString()
  );
};

export const isYesterday = (value) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return (
    value[0].toDateString() === yesterday.toDateString() &&
    value[1].toDateString() === yesterday.toDateString()
  );
};

export const isLastWeek = (value) => {
  const today = new Date();
  const lastDay = new Date();
  lastDay.setDate(lastDay.getDate() - 6);
  return (
    value[0].toDateString() === lastDay.toDateString() &&
    value[1].toDateString() === today.toDateString()
  );
};

export const isLast30Days = (value) => {
  const today = new Date();
  const lastDay = new Date();
  lastDay.setDate(lastDay.getDate() - 30);
  return (
    value[0].toDateString() === lastDay.toDateString() &&
    value[1].toDateString() === today.toDateString()
  );
};

export const textCapitalize = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const repeatReminderSummary = (schedule) => {
  // frequency
  var frequency = schedule.duration;
  if (schedule.frequency > 1) {
    frequency = `${schedule.frequency} ${frequency}s`;
  }
  // days
  var result = `Repeat every ${frequency}`;
  if (schedule.duration == "week") {
    var days = "";
    if (schedule.weekdays.length == 7) {
      days = "all days";
    } else {
      var days_arr = [];
      var weekdays = schedule.weekdays;
      weekdays
        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
        .forEach((weekday) => {
          days_arr.push(
            ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"][
              parseInt(weekday) - 1
            ],
          );
        });
      days = days_arr.join();
    }
    result += ` on ${days}`;
  }

  return result;
};

export const validTemplateText = (text) => {
  if (text == null) {
    return true;
  }
  // Param text cannot have new-line\\/tab characters or more than 4 consecutive spaces
  const regex = /(\s)\1+/g;
  return !text.includes("\n") && !text.match(regex)?.some((i) => i.length > 4);
};

export const formatPercentage = (numerator, denominator) => {
  return `${((numerator / denominator) * 100).toFixed(2).replace(/[.,]00$/, "")}%`;
};

export const errorStyles = (baseErrors) => {
  return { display: baseErrors ? "block" : "none", color: "red" };
};
