import React, { useState, useMemo } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Loader,
  Panel,
  ButtonToolbar,
  ButtonGroup,
} from "rsuite";
import classNames from "classnames";
import insertTextAtCursor from "insert-text-at-cursor";
import axios from "axios";
import { getAuthenticityToken } from "../../utils/authenticity_token";
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";

const Textarea = React.forwardRef(function Textarea(props, ref) {
  return <Input {...props} as="textarea" ref={ref} />;
});

const FormModal = ({ open, onClose, onConfirm, template }) => {
  const prefix = "{STUDENT_NAME} ";

  const [state, setState] = useState({
    title: template?.attributes?.title || "",
    template: {
      en: template?.attributes?.template?.en || prefix,
      ps: template?.attributes?.template?.ps || prefix,
      fa: template?.attributes?.template?.fa || prefix,
    },
  });

  const [isUploading, setIsUploading] = useState(false);

  const { data: languagesData } = useSWR({ url: "/api/v0/languages" }, fetcher);

  const [language, setLanguage] = useState(null);

  const [errors, setErrors] = useState(null);

  const languages = useMemo(() => {
    const result = languagesData
      ? languagesData["data"].map((language) => ({
          value: language.id,
          label: language.attributes.name,
          key: language.attributes.code,
        }))
      : [];
    setLanguage(result?.[0]);
    return result;
  }, [languagesData]);

  const TEMPLATE_VARIABLES = [
    "{COURSE_TITLE}",
    "{COURSE_GRADE}",
    "{COURSE_TYPE}",
  ];

  const attachPrefix = (input) => prefix + input.substr(prefix.length);

  const currentElement = () =>
    document.getElementById(`template-${language?.key}`);
  const insertAtCursor = (variable) => {
    const element = currentElement();
    if (element.selectionStart < prefix.length) {
      setErrors({
        template: `place the cursor at the right position to insert the variable`,
      });
      return;
    }

    if (element.textContent.includes(variable)) {
      setErrors({ template: `${variable} is already added` });
      return;
    }

    insertTextAtCursor(element, variable);
  };

  return (
    <Modal open={open} onClose={onClose} size="lg" backdrop="static">
      <Modal.Header>
        <Modal.Title>Add Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <br />
        {isUploading ? (
          <Panel className="d-flex justify-content-center">
            <Loader content="uploading" size="lg" />
          </Panel>
        ) : (
          <>
            <Form fluid>
              {/* template title*/}
              <Form.Group>
                <Form.ControlLabel
                  className={classNames({
                    "text-danger": (state.title?.length || 0) > 50,
                  })}
                >
                  <div className="d-flex">
                    Title<span className="text-danger">*</span>
                    <span className="ms-auto light-txt small">
                      ( {state.title?.length || 0} / 50 )
                    </span>
                  </div>
                </Form.ControlLabel>

                <Form.Control
                  id="title"
                  name="template"
                  value={state.title}
                  onChange={(value) => {
                    if (value.length > 180) return;
                    setState({ ...state, title: value });
                  }}
                  errorMessage={errors?.title}
                />
              </Form.Group>
              <br />

              {/* language tabs */}
              <div className="mb-3">
                <ButtonToolbar>
                  <ButtonGroup
                    justified
                    className="d-flex justify-content-between bb"
                  >
                    {languages?.map((lang, index) => (
                      <Button
                        appearance="subtle"
                        key={lang.label}
                        active={language?.key == lang?.key ? true : false}
                        onClick={() => {
                          setLanguage(languages?.[index]);
                          setErrors(null);
                        }}
                      >
                        {lang.label}
                      </Button>
                    ))}
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
              <br />

              {/* template variables */}
              {TEMPLATE_VARIABLES.map((variable) => (
                <>
                  <div
                    className="d-flex justify-content-between"
                    key={variable}
                  >
                    <label className="f-med mb-1" htmlFor="Occurance">
                      {variable}
                    </label>
                    <Button
                      color="green"
                      className="ms-2 h-auto pe-0"
                      appearance="link"
                      size="xs"
                      onClick={() => insertAtCursor(variable)}
                    >
                      <i className="fa fa-plus me-2"></i>
                      Add
                    </Button>
                  </div>
                  <br />
                </>
              ))}
              <br />

              {/* template text */}
              <Form.Group>
                <Form.ControlLabel
                  className={classNames({
                    "text-danger":
                      (state.template[language?.key]?.length || 0) > 180,
                  })}
                >
                  <div className="d-flex">
                    Template {language?.label}
                    <span className="text-danger">*</span>
                    <span className="ms-auto light-txt small">
                      ( {state.template[language?.key]?.length || 0} / 180 )
                    </span>
                  </div>
                </Form.ControlLabel>

                <Form.Control
                  id={`template-${language?.key}`}
                  name="template"
                  accepter={Textarea}
                  rows={3}
                  value={state.template[language?.key]}
                  onChange={(value) => {
                    if (value.length > 180) return;
                    if (value?.length > 0 && !value.startsWith(prefix)) return;

                    const newTemplate = state.template;
                    newTemplate[language?.key] = attachPrefix(value);
                    setState({ ...state, template: newTemplate });
                    setErrors(null);
                  }}
                  errorMessage={errors?.template}
                  dir={`${["ps", "fa"].includes(language?.key) ? "rtl" : "auto"}`}
                />
              </Form.Group>
            </Form>
          </>
        )}
        <br />
      </Modal.Body>
      <Modal.Footer className="d-flex">
        <Button onClick={onClose} appearance="subtle" block className="me-2">
          Cancel
        </Button>
        <Button
          onClick={() => {
            setErrors(null);
            setIsUploading(true);
            const id = template?.id;
            const method = id ? "PUT" : "POST";
            const url = id
              ? `/api/v0/certificate_templates/${id}`
              : "/api/v0/certificate_templates";
            axios({
              method,
              url,
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getAuthenticityToken(),
              },
              data: state,
            })
              .then((response) => {
                setErrors(null);
                return onConfirm(response.data);
              })
              .catch((error) => {
                setIsUploading(false);
                setErrors(error?.response?.data?.errors);
              });
          }}
          color="green"
          appearance="primary"
          className="mt-0 ms-2"
          block
        >
          {template != null ? "Update" : "Create"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FormModal;
