import { today, last7Days, generateRandomNumber } from "./helper";

export const initalState = {
  color: "#198754",
  formattedData: [],
  chartsData: [],
  chartIndex: 0,
  chartsCount: 1,
  dataByChartIndex: {
    0: {
      startDate: last7Days,
      endDate: today,
      age: null,
      gender: null,
      country: null,
      provinceIds: [],
      data: [],
      chartName: `chart ${generateRandomNumber()}`,
    },
  },
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "setStartDate":
      return { ...state, startDate: action.payload };
    case "setEndDate":
      return { ...state, endDate: action.payload };
    case "setAge":
      return { ...state, age: action.payload };
    case "setGender":
      return { ...state, gender: action.payload };
    case "setCountry":
      return { ...state, country: action.payload };
    case "setProvinceIds":
      return { ...state, provinceIds: action.payload };
    case "setChartsData":
      return { ...state, chartsData: action.payload };
    case "setColor":
      return { ...state, color: action.payload };
    case "setFormattedData":
      return { ...state, formattedData: action.payload };
    case "setChartsCount":
      return { ...state, chartsCount: action.payload };
    case "setChartIndex":
      return { ...state, chartIndex: action.payload };
    case "setDataByChartIndex":
      return { ...state, dataByChartIndex: action.payload };
    default:
      return state;
  }
};
