import React, { useEffect, useState } from "react";
import { Form, Button } from "rsuite";

const fillTheGapInitialState = () => ({
  answers: [
    {
      id: 1,
      value: "The Answer",
      weight: 100,
    },
  ],
});

const newFillTheGapAnswer = (state) => ({
  id: Math.ceil(Math.random() * 1000000) + 3,
  value: fieldName(state),
  weight: 100,
});

const fieldName = (state) => {
  let lastFieldValueNumber = 0;
  state["answers"].forEach(function (d) {
    const newFieldValue = d.value;
    if (newFieldValue != "" && newFieldValue != null) {
      const fieldValueNumber = newFieldValue.split("New Answer")[1];
      if (fieldValueNumber != undefined) {
        lastFieldValueNumber = parseInt(fieldValueNumber);
      }
    }
  });
  return "New Answer " + (lastFieldValueNumber + 1);
};

const FillTheGap = ({ data, onChange }) => {
  const [state, setState] = useState(data || fillTheGapInitialState());
  useEffect(() => {
    onChange(state);
  }, [state]);

  return (
    <Form.Group>
      <div className="d-flex justify-content-between align-items-center">
        <Form.ControlLabel>
          Answers<span className="text-danger">*</span>
        </Form.ControlLabel>
        <Button
          color="green"
          className="ms-2"
          appearance="link"
          size="xs"
          onClick={() => {
            const answers = [...state.answers, newFillTheGapAnswer(state)];
            setState({ ...state, answers });
          }}
        >
          <i className="fa fa-plus me-2"></i>
          Add New Answer
        </Button>
      </div>
      {state.answers.map((answer, index) => (
        <div
          key={`key-${index}`}
          style={{ position: "relative" }}
          className="rst-radio mb-3"
        >
          <Form.Control
            name="question"
            value={answer.value}
            className="p-2"
            onChange={(value) => {
              if (value.length > 24) return;
              const answers = state.answers.map((item) => ({
                ...item,
                value: item.id === answer.id ? value : item.value,
              }));
              setState({ ...state, answers });
            }}
            required
          />
          {state.answers.length > 1 && (
            <div
              className="p-2"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                padding: "4px",
              }}
            >
              <i
                className="fa-solid fa-xmark close"
                role="button"
                tabIndex={0}
                onClick={() => {
                  const answers = state.answers.filter(
                    (item) => item.id !== answer.id,
                  );
                  setState({ ...state, answers });
                }}
                onKeyDown={() => {
                  const answers = state.answers.filter(
                    (item) => item.id !== answer.id,
                  );
                  setState({ ...state, answers });
                }}
              ></i>
            </div>
          )}
          <div className="limit">( {answer?.value?.length || 0}/ 24)</div>
        </div>
      ))}
    </Form.Group>
  );
};

export default FillTheGap;
