const today = new Date();
const lastDay = new Date();
const last7Days = new Date();
const last30Days = new Date();
lastDay.setDate(lastDay.getDate() - 1);
last7Days.setDate(last7Days.getDate() - 6);
last30Days.setDate(last30Days.getDate() - 30);

export { today, lastDay, last7Days, last30Days };

export const generateRandomNumber = () => Math.ceil(Math.random() * 1000);

export const mergeArrays = (arrays) => {
  const result = {};
  arrays.forEach((array, index) => {
    if (array === undefined) return;
    const key = `chart-${index + 1}`;
    array.forEach((element) => {
      const { date } = element;
      if (!result[date]) {
        result[date] = {};
      }
      result[date][key] = {
        new: element.new,
        return: element.return,
      };
    });
  });

  return Object.keys(result)
    .sort((a, b) => new Date(a) - new Date(b))
    .map((key) => ({
      ...result[key],
      date: key,
    }));
};

export const combineDates = (newDates, returnDates) => {
  const allDates = new Set([
    ...Object.keys(newDates ?? {}),
    ...Object.keys(returnDates ?? {}),
  ]);
  return Array.from(allDates);
};

export const hasData = (items) => {
  return items.some((obj) => {
    // Loop through all keys in the object
    return Object.values(obj).some((value) => {
      // If the value is an object, check its properties
      if (typeof value === "object" && value !== null) {
        return Object.values(value).some((nestedValue) => nestedValue > 0);
      }
      return false;
    });
  });
};
