import React, { useReducer } from "react";
import { Outlet, Routes, Route, Navigate } from "react-router-dom";
import PageTitle from "../../components/page_title";
import SubpageNavbar from "../../components/subpage_navbar";
import Content from "./content";
import Schedule from "./schedule";
import { Button } from "rsuite";
import { initalState, reducer } from "./reducer";
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";

const items = [
  {
    to: "schedule",
    label: "Schedule",
  },
  {
    to: "content",
    label: "Content",
  },
];

const Wrapper = ({ onClose, id, dispatch }) => {
  return (
    <div className="container">
      <div className="mb-3">
        <div className="mb-3">
          <div className="row align-items-center">
            <div className="col-md-4">
              <PageTitle title={!id ? "New Reminder" : "Edit Reminder"} />
            </div>
            <div className="col-md-8">
              <div className="d-flex justify-content-end">
                <Button
                  appearance="ghost"
                  className="px-3 me-2"
                  color="green"
                  onClick={() => {
                    dispatch({
                      type: "setCopied",
                      payload: true,
                    });
                    onClose();
                  }}
                >
                  <i className="fa fa-angle-left"></i>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shadow-sm bg-white radius-5 tab-sec">
        <div className="top">
          {items.length > 1 && <SubpageNavbar items={items} />}
        </div>
        <div className="inner">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const Index = ({ page, pageName, id, editable, onClose }) => {
  const [state, dispatch] = useReducer(reducer, initalState);
  const { data: reminders } = useSWR(
    id
      ? {
          url: `/api/v0/reminders/${id}`,
        }
      : null,
    fetcher,
  );
  return (
    <Routes>
      <Route
        path="*"
        element={<Wrapper onClose={onClose} id={id} {...{ state, dispatch }} />}
      >
        <Route
          path="schedule"
          element={
            <Schedule
              onClose={onClose}
              id={id}
              editable={editable}
              reminders={reminders}
              {...{ state, dispatch }}
            />
          }
        />
        {id || state?.filters?.language_id?.length ? (
          <Route
            path="content"
            element={
              <Content
                {...{ state, dispatch }}
                id={id}
                reminders={reminders}
                editable={editable}
                page={page}
                pageName={pageName}
                onClose={onClose}
              />
            }
          />
        ) : (
          <Route path="content" element={<Navigate to="schedule" />} />
        )}
        <Route path="*" element={<Navigate to="schedule" />} />
      </Route>
    </Routes>
  );
};

export default Index;
