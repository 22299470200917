import React from "react";
import { Link } from "react-router-dom";
import { getGradeList } from "../utils";
import GraduationImg from "../../../../../../javascript/images/graduation-cap-flat-icon.jpg";

const Card = ({ course, page, handleEdit, handleDelete }) => {
  return (
    <div className="col-xl-3 col-md-4 col-sm-6">
      <div className="card tile-wrap mb-4 overflow-hidden">
        <div className="top">
          <div className="text-white pl-2 ms-auto d-flex icons">
            <i
              className="fa-solid fa-pencil me-2 cursor-pointer"
              onClick={() => handleEdit(course)}
              aria-hidden="true"
            ></i>
            <i
              className="fa-solid fa-trash cursor-pointer"
              onClick={() => handleDelete(course)}
              aria-hidden="true"
            ></i>
          </div>
          <span className="name f-16 f-med ellipse text-white">
            {course?.attributes?.title ?? ""}
          </span>
          <div className="image">
            <img
              className="img-fluid course-img"
              src={course?.attributes?.url || GraduationImg}
              alt="Course"
            />
          </div>
        </div>

        <div className="card-body pb-2">
          <div className="innnner">
            <div className="d-flex flex-wrap">
              <div className="w-50">
                <div className="d-flex f-med mb-2">
                  <span className="grey-txt me-2">Enrolled: </span>
                  <span className="f-med">
                    {course?.attributes?.enrolled_count ?? 0}
                  </span>
                </div>

                <div className="d-flex f-med mb-2">
                  <span className="grey-txt me-2">Certfied: </span>
                  <span className="f-med">
                    {course?.attributes?.certified_count ?? 0}
                  </span>
                </div>
              </div>
              <div className="w-50">
                <div className="d-flex f-med mb-2">
                  <span className="grey-txt me-2">Language: </span>
                  <span className="f-med">
                    {course?.attributes?.language ?? ""}
                  </span>
                </div>
                <div className="d-flex f-med mb-2">
                  <span className="grey-txt me-2 text-nowrap">
                    Course Grade:{" "}
                  </span>
                  <span className="f-med ellipse" title={getGradeList(course)}>
                    {getGradeList(course)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer bg-white">
          <div className="d-flex justify-content-between">
            <span className="grey-txt f-med">
              {course?.attributes?.published ? "Published" : "Un-Published"}
            </span>
            <Link
              to={`/courses/${course.id}/summary/?type=grid&page=${page}`}
              className="f-semi"
            >
              View <i className="fa fa-angle-right ms-1"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
