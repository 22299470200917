import React, { Fragment, useState } from "react";
import PageTitle from "../../components/page_title";
import { Form, SelectPicker } from "rsuite";
import { isLoggedIn, websiteUrl } from "../../utils/state";
import { useParams, useSearchParams } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import LoaderSpinner from "../certificate_templates/loader_spinner";

const Index = () => {
  const [language, setLangage] = useState("en");
  const [loading, setLoading] = useState(true);
  const { "*": token = "preview" } = useParams();
  const [searchParams] = useSearchParams();
  const template_id = searchParams.get('template_id');
  const certificate_code = token.trim() === "" ? "preview" : token;

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

  return (
    <Container>
      <div className="container">
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <PageTitle title={"Certificate"} />
          <a
            href={`/api/v0/certificates/${certificate_code}.pdf?language_code=${language}&template_id=${template_id}`}
            rel="noopener noreferrer"
            download={`certificate.pdf`}
            className="theme-btn sm f-14"
            role="button"
          >
            <i className="fa fa-download me-2"></i>
            Download
          </a>
        </div>

        <div className="shadow-sm bg-white radius-5 overflow-hidden">
          <div className="inner px-4 mx-md-2 pt-4">
            <Form className="mb-0">
              <Form.Group className="d-flex justify-content-between mb-0">
                <Form.Control
                  name="language"
                  accepter={SelectPicker}
                  data={[
                    { value: "ps", label: "Pashto" },
                    { value: "fa", label: "Dari" },
                    { value: "en", label: "English" },
                  ]}
                  labelKey="label"
                  valueKey="value"
                  onChange={(value) => {
                    setLangage(value);
                    setLoading(true);
                  }}
                  value={language}
                  cleanable={false}
                />
                <h5>
                  <a href={websiteUrl()}>Visit Solax website</a>
                </h5>
              </Form.Group>
            </Form>
            {loading && <LoaderSpinner paragraph />}
          </div>
          <div
            style={{
              aspectRatio: "2",
              textAlign: "center",
            }}
          >
            <Document
              file={`/api/v0/certificates/${certificate_code}.pdf?language_code=${language}&template_id=${template_id}`}
              onLoadSuccess={() => setLoading(false)}
            >
              <Page
                pageNumber={1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                width={1100}
                height={"auto"}
              />
            </Document>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = ({ children }) =>
  isLoggedIn() ? (
    <Fragment>{children}</Fragment>
  ) : (
    <div className="container">{children}</div>
  );

export default Index;
