import React, { useState, useEffect } from "react";
import { Table, Pagination } from "rsuite";
import groupBy from "lodash/groupBy";
import Spinner from "../../../components/spinner";
import useSWR from "swr";
import { fetcher } from "../../../utils/fetcher";
import SelectFilter from "../../../components/select";
import { generateXLSXUrl } from "../../../utils/xlsx";

const { Column, Cell: OriginalCell, HeaderCell: OriginalHeaderCell } = Table;

const HeaderCell = (props) => (
  <OriginalHeaderCell {...props} style={{ padding: 10 }} />
);

const Cell = (props) => <OriginalCell {...props} style={{ padding: 10 }} />;

const groupByCourse = (data) =>
  groupBy(data || [], (item) => item.course_title);

const Lessons = () => {
  const [courseId, setCourseId] = useState(null);
  const [formattedData, setFormattedData] = useState([]);
  const url = "/api/v0/analytics/lessons";
  const payload = () => ({ course_id: courseId });
  const {
    data = [],
    isLoading,
    mutate,
  } = useSWR(
    {
      url: url,
      params: payload(),
    },
    fetcher,
  );

  const ALL_COURSES = [{ value: "", label: "All Courses" }];
  const { data: courses = { data: [] } } = useSWR(
    {
      url: "/api/v0/courses",
      params: {
        published: true,
      },
    },
    fetcher,
  );

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const startIndex = (page - 1) * limit;
  const [total, setTotal] = useState(0);
  const endIndex = Math.min(startIndex + limit, total);
  const [showNextButton, setShowNextButton] = useState(true);

  useEffect(() => {
    if (!(data?.data?.length || data?.length)) {
      setFormattedData([]);
      setTotal(0);
      setShowNextButton(false);
      return;
    }

    const groupedData = groupByCourse(data);
    const allRecords = Object.keys(groupedData || {}).map((key) => {
      const children = groupedData[key].map((item) => ({
        ...item,
        course_title: "", // Remove course title in the child items
      }));
      return {
        id: key,
        course_title: key,
        children,
      };
    });
    setTotal(allRecords?.length);
    setShowNextButton(page * limit < allRecords.length);
    const pageRecords = allRecords.slice((page - 1) * limit, page * limit);
    setFormattedData(pageRecords);
  }, [page, data, limit]);

  if (isLoading) return <Spinner />;

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <fieldset className="me-3 d-flex align-items-center">
          <label className="grey-txt form-group me-2" htmlFor="GroupBy">
            Group By
          </label>
          <SelectFilter
            value={courseId || ""}
            onChange={(e) => {
              setCourseId(e.target.value);
              mutate();
            }}
            options={[
              ...ALL_COURSES,
              ...(courses?.data || []).map((course) => ({
                value: course?.id,
                label: course?.attributes?.title,
              })),
            ]}
          />
        </fieldset>
        <a className="theme-btn" href={generateXLSXUrl(url, payload())}>
          <i className="fa fa-download me-2"></i>
          Download as XLSX
        </a>
      </div>
      <div className="mx--3">
        {formattedData.length === 0 ? (
          <div className="text-center py-5">No records found</div>
        ) : (
          <Table
            data={formattedData}
            height={450}
            width={"100wv"}
            isTree
            rowKey="id"
          >
            <Column width={200} align="center" flexGrow={2} treeCol>
              <HeaderCell>Course</HeaderCell>
              <Cell dataKey="course_title" />
            </Column>
            <Column width={200} align="center" flexGrow={2}>
              <HeaderCell>Lesson</HeaderCell>
              <Cell dataKey="lesson_title" />
            </Column>
            <Column width={200} align="center" flexGrow={2}>
              <HeaderCell>Students</HeaderCell>
              <Cell dataKey="student_count" />
            </Column>
          </Table>
        )}
        {((formattedData || []).length >= 10 || page > 1) && (
          <div className="d-flex px-3 justify-content-between align-items-center">
            <div className="grey-txt mt-2 f-14">
              {startIndex + 1} to {endIndex && endIndex != 0 ? endIndex : ""} of{" "}
              {total}
            </div>
            <div className="pagination-outer">
              <Pagination
                prev
                next={!isLoading && showNextButton}
                ellipsis
                layout={["limit", "|", "pager"]}
                size="sm"
                activePage={page}
                onChangePage={(page) => setPage(page)}
                onChangeLimit={(limit) => {
                  setLimit(limit), setPage(1);
                }}
                limit={limit}
                limitOptions={[10, 30, 50]}
                total={total}
                maxButtons={10}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Lessons;
