import React from "react";
import Card from "./Card";

const Grid = ({ courses, page, handleEdit, handleDelete }) => {
  return (
    <div className="px-3 pt-3">
      <div className="row">
        {courses?.length ? (
          courses?.map((course, index) => (
            <Card
              key={index}
              course={course}
              page={page}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ))
        ) : (
          <div
            className="text-center d-flex flex-column justify-content-center align-items-center"
            style={{ minHeight: "500px" }}
          >
            No data found
          </div>
        )}
      </div>
    </div>
  );
};

export default Grid;
