import React, { useState } from "react";
import { Button, Steps, Panel } from "rsuite";
import StepOne from "./steps/1";
import StepTwo from "./steps/2";
import StepThree from "./steps/3";
import ConfirmAttach from "./steps/confirm_attach";
import LinkCopied from "./steps/link_copied";

const FIRST_STEP = 0;
const SECOND_STEP = 1;
const THIRD_STEP = 2;
const FOURTH_STEP = 3;

const OPTION_COPY_LINK = "copy_link";
const OPTION_ATTACH_FILE = "attach_file";

const AttachFileModal = ({
  onAttach,
  onClose,
  disableAttach,
  disableAttachMessage,
  support_content_type,
}) => {
  /*
    step: 1 - select file
    step: 2 - upload file
    step: 3 - decide how to attach
    step: 4 - Confirmation
  */
  const [state, setState] = useState({
    step: FIRST_STEP,
    file: null,
    file_url: null,
    file_meta_data: [],
    selectedOption: null,
  });

  return (
    <>
      <Panel header="Attach a file" bordered>
        <Steps current={state.step} className="mb-2">
          <Steps.Item title="Select file" />
          <Steps.Item title="Upload" />
          <Steps.Item title="How to Share" />
          <Steps.Item title="Confirmation" />
        </Steps>

        {state.step === FIRST_STEP && (
          <StepOne
            onChange={(file) =>
              setState({ ...state, step: SECOND_STEP, file: file })
            }
          />
        )}

        {state.step === SECOND_STEP && (
          <StepTwo
            file={state.file}
            onUploaded={(file_url) =>
              setState({ ...state, step: THIRD_STEP, file_url: file_url })
            }
          />
        )}

        {state.step === THIRD_STEP && (
          <StepThree
            file_url={state.file_url}
            file_meta_data={state.file_meta_data}
            disableAttach={disableAttach}
            disableAttachMessage={disableAttachMessage}
            support_content_type={support_content_type}
            setMetadata={(file_meta_data) =>
              setState({ ...state, file_meta_data: file_meta_data })
            }
            onLinkCopied={() =>
              setState({
                ...state,
                step: FOURTH_STEP,
                selectedOption: OPTION_COPY_LINK,
              })
            }
            onAttachFileSelected={(authenticated) => {
              if (authenticated) {
                setState({
                  ...state,
                  step: FOURTH_STEP,
                  selectedOption: OPTION_ATTACH_FILE,
                });
              } else {
                setState({
                  ...state,
                  file_meta_data: {
                    ...state.file_meta_data,
                    potentially_supported_as_interactive_message_header: false,
                  },
                });
              }
            }}
          />
        )}
        {state.step === FOURTH_STEP &&
          state.selectedOption === OPTION_ATTACH_FILE && (
            <ConfirmAttach
              file_meta_data={state.file_meta_data}
              onConfirm={() => {
                onAttach(
                  state.file_url,
                  state?.file_meta_data?.content_type,
                  state?.file?.name,
                );
              }}
              onCancel={() =>
                setState({
                  ...state,
                  step: THIRD_STEP,
                  selectedOption: null,
                  file_meta_data: [],
                })
              }
            />
          )}
        {state.step === FOURTH_STEP &&
          state.selectedOption === OPTION_COPY_LINK && (
            <LinkCopied
              onCancel={() => setState({ ...state, step: THIRD_STEP })}
            />
          )}
      </Panel>
      <Button onClick={onClose} color="red" appearance="ghost" className="mt-2">
        Close
      </Button>
    </>
  );
};

export default AttachFileModal;
