import React from "react";

// ReCharts
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import NoDataFound from "../../../components/bar_chart/noDataFound";

const GradeBarChart = ({ hasData, gradeChartData, startDate, legendData }) => {
  if (!hasData) return <NoDataFound />;
  return (
    <ResponsiveContainer width="100%" height={380}>
      <BarChart data={gradeChartData ?? []}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          angle={-45}
          textAnchor="end"
          tick={{ fontSize: 12 }}
          dataKey="grade"
          label={{
            value: "Course Grades",
            position: "insideBottom",
            offset: "-9",
          }}
        />
        <YAxis
          allowDecimals={false}
          label={{
            value: "Numbers",
            angle: -90,
            position: "left",
            offset: "-3",
          }}
        />
        <Tooltip />
        <Legend verticalAlign="top" height={34} iconType="square" />
        <Bar
          name={`Enrollments (${legendData?.enrollmentsCount || 0}) `}
          dataKey="enrollments"
          fill="#0d6d18"
        />
        <Bar
          name={`Certificates Issued (${legendData?.certificatesCount || 0}) `}
          dataKey="certificates"
          fill="#18181a"
        />
        <Bar
          name={`Lessons Requested (${legendData?.contentRequestedCount || 0}) `}
          dataKey="contentRequested"
          fill="#43C4C7"
        />
        {new Date(startDate) > new Date("2024-05-07") && (
          <Bar
            name={`Requested Lessons Completed (${legendData?.contentCompletedCount || 0}) `}
            dataKey="contentCompleted"
            fill="#FF8042"
          />
        )}
        <Bar
          name={`Total Lessons Completed (${legendData?.totalContentCompletedCount || 0}) `}
          dataKey="totalContentCompleted"
          fill="#B442FF"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default GradeBarChart;
