import React, { Fragment, useEffect, useReducer } from "react";
import useSWR from "swr";
import styled from "styled-components";
import { fetcher } from "../../../utils/fetcher";
import Spinner from "../../../components/spinner";
import SelectFilter from "../../../components/select";
import {
  ALL_GRADES,
  ALL_LANGUAGES,
  ALL_TEACHERS,
  initalState,
  reducer,
} from "./reducer";
import BarChart from "../../../components/bar_chart";
import Filter from "./filter";
import NoDataFound from "../../../components/bar_chart/noDataFound";
import { gradeOptionFormatter } from "../utils";
import { generateXLSXUrl } from "../../../utils/xlsx";

export const GROUP_BY_LIST = [
  { value: "month", label: "By Month" },
  { value: "day", label: "By Day" },
];

const ChartContainer = styled.div`
  width: 100%;
  height: 520px;
`;

const Courses = () => {
  const [state, dispatch] = useReducer(reducer, initalState);
  const { groupBy, formattedData, axisContent } = state;

  const { data: teachersData } = useSWR(
    { url: "/api/v0/users", params: { role: "teacher" } },
    fetcher,
  );

  const url = "/api/v0/analytics/courses";
  const { data, isLoading } = useSWR(
    { url, params: payload({ ...state }) },
    fetcher,
  );

  const { data: gradesData } = useSWR({ url: "/api/v0/grades" }, fetcher);
  const { data: languagesData } = useSWR({ url: "/api/v0/languages" }, fetcher);

  useEffect(() => {
    if (!data) return;
    const formattedData = Object.keys(data).map((key) => {
      const [year, month, day] = JSON.parse(key);
      const name =
        groupBy === "month" ? `${year}-${month}` : `${year}-${month}-${day}`;
      return { name, value: data[key] };
    });

    formattedData.sort((a, b) => {
      return new Date(a.name) - new Date(b.name);
    });
    dispatch({ type: "setFormattedData", payload: formattedData });
  }, [data]);

  useEffect(() => {
    if (!teachersData) return;
    const options = teachersData["data"].map((teacher) => ({
      value: teacher.id,
      label: teacher.attributes.email,
    }));
    dispatch({
      type: "setTeacherOptions",
      payload: [...ALL_TEACHERS, ...options],
    });
  }, [teachersData]);

  useEffect(() => {
    if (!gradesData) return;
    const options = gradesData["data"].map(gradeOptionFormatter);
    dispatch({
      type: "setGradeOptions",
      payload: [...ALL_GRADES, ...options],
    });
  }, [gradesData]);

  useEffect(() => {
    if (!languagesData) return;
    const options = languagesData["data"].map((language) => ({
      value: language.id,
      label: language.attributes.name,
    }));
    dispatch({
      type: "setLanguageOptions",
      payload: [...ALL_LANGUAGES, ...options],
    });
  }, [languagesData]);

  useEffect(() => {
    if (!groupBy) return;
    const axisContentData = [
      { value: groupBy === "month" ? "Months" : "Days" },
      { value: "Number of course(s) created" },
    ];

    dispatch({
      type: "setAxisContent",
      payload: axisContentData,
    });
  }, [groupBy]);

  if (isLoading) return <Spinner />;
  return (
    <Fragment>
      <div className="d-flex justify-content-end mb-3">
        <fieldset className="me-3 d-flex align-items-center">
          <label className="grey-txt form-group me-2" htmlFor="GroupBy">
            Group By
          </label>
          <SelectFilter
            value={groupBy}
            onChange={(e) =>
              dispatch({ type: "setGroupBy", payload: e.target.value })
            }
            options={GROUP_BY_LIST}
          />
        </fieldset>
        <a
          className="theme-btn"
          href={generateXLSXUrl(url, payload({ ...state }))}
        >
          <i className="fa fa-download me-2"></i>
          Download as XLSX
        </a>
      </div>
      <div>
        <Filter {...{ state, dispatch }} />
      </div>

      <ChartContainer>
        {formattedData.length > 0 ? (
          <BarChart data={formattedData} axisContent={axisContent} />
        ) : (
          <NoDataFound />
        )}
      </ChartContainer>
    </Fragment>
  );
};

const payload = ({
  groupBy,
  startDate,
  endDate,
  teacherId,
  gradeId,
  schoolLevelId,
  published,
  archived,
  languageId,
}) => ({
  group_by: groupBy,
  start_date: startDate,
  end_date: endDate,
  teacher_id: teacherId,
  grade_id: gradeId || schoolLevelId,
  published,
  archived,
  language_id: languageId,
});
export default Courses;
