import React from "react";

const ChartSettings = ({ chartName, updateChartName }) => {
  return (
    <div className="row mt-3">
      <div className="col-md-3">
        <div className="form-group">
          <label className="f-med mb-1" htmlFor="chartName">
            Chart Name
          </label>
          <input
            type="text"
            name="chartName"
            className="form-control theme-ip"
            value={chartName}
            maxLength={20}
            onChange={(e) => updateChartName(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartSettings;
