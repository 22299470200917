// Core
import React, { Fragment, useState, useEffect, useReducer } from "react";
import classNames from "classnames";
import useSWR from "swr";

// Rsuite
import { Whisper, Tooltip as RTooltip, Button, Loader } from "rsuite";

// ReCharts
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
} from "recharts";

// Components
import Chart from "./chart";
import ChartSettings from "./chartSettings";
import NoDataFound from "../../../components/bar_chart/noDataFound";
import NoDataPieChart from "../../../components/pie_chart/noDataPieChart";

// helpers
import { fetcher } from "../../../utils/fetcher";
import { initalState, reducer } from "./reducer";
import {
  today,
  last7Days,
  mergeArrays,
  combineDates,
  generateRandomNumber,
} from "./helper";
import {
  format_end_of_day_in_time_zone,
  format_start_of_day_in_time_zone,
} from "../../../utils/date_formatter";
import { formatPercentage } from "../../../utils/helpers";

const COLORS = ["#198754", "#FFFFF", "#E2DED0"];

const StudentEngagement = () => {
  const [state, dispatch] = useReducer(reducer, initalState);
  const {
    chartsCount,
    chartIndex,
    formattedData,
    chartsData,
    dataByChartIndex,
  } = state;

  const [isLoading, setIsLoading] = useState(false);
  const [piChart, setPiChart] = useState({
    graph: [
      {
        name: "New Active Users",
        users: 0,
      },
      {
        name: "Return Active Users",
        users: 0,
      },
      {
        name: "Inactive Users",
        users: 0,
      },
    ],
    percentage: 0,
  });

  const chartName = dataByChartIndex[chartIndex]?.chartName ?? "";

  const { data, isLoading: loading } = useSWR(
    {
      url: "/api/v0/analytics/students_engagement",
      params: payload({ ...dataByChartIndex[chartIndex] }),
    },
    fetcher,
  );

  useEffect(() => {
    if (!data) return;
    const items = combineDates(data?.daily?.new, data?.daily?.return).map(
      (date) => ({
        date: new Date(date.slice(1, -1)).toLocaleDateString(), // Convert string date to Date object
        new: data?.daily?.new[date] ?? 0,
        return: data?.daily?.return[date] ?? 0,
      }),
    );

    const array = [...chartsData];
    array[chartIndex] = items;
    const updatedDataByChartIndex = {
      ...dataByChartIndex,
      [chartIndex]: {
        ...dataByChartIndex[chartIndex],
        data: items,
      },
    };

    dispatch({
      type: "setChartsData",
      payload: array,
    });
    dispatch({
      type: "setDataByChartIndex",
      payload: updatedDataByChartIndex,
    });
    handlePiChart(data);
  }, [data]);

  useEffect(() => {
    dispatch({
      type: "setFormattedData",
      payload: mergeArrays(chartsData),
    });
  }, [chartsData]);

  // method to handle pichart data
  const handlePiChart = (data) => {
    const inactiveUsers =
      data?.summary?.total - (data?.summary?.new + data?.summary?.return);
    setPiChart({
      ...piChart,
      graph: [
        {
          name: `New Active Users (${formatPercentage(data?.summary?.new, data?.summary?.total)})`,
          users: data?.summary?.new,
        },
        {
          name: `Return Active Users (${formatPercentage(data?.summary?.return, data?.summary?.total)})`,
          users: data?.summary?.return,
        },
        {
          name: `Inactive Users (${formatPercentage(inactiveUsers, data?.summary?.total)})`,
          users: inactiveUsers,
        },
      ],
      percentage: formatPercentage(
        data?.summary?.new + data?.summary?.return,
        data?.summary?.total,
      ),
    });
  };

  // method to update chart name.
  const updateChartName = (index, name) => {
    dispatch({
      type: "setDataByChartIndex",
      payload: {
        ...dataByChartIndex,
        [index]: {
          ...dataByChartIndex[index],
          chartName: name,
        },
      },
    });
  };

  // method to add new chart
  const addChart = () => {
    setIsLoading(true);
    dispatch({
      type: "setChartsCount",
      payload: chartsCount + 1,
    });
    dispatch({
      type: "setDataByChartIndex",
      payload: {
        ...dataByChartIndex,
        [chartsCount]: {
          startDate: last7Days,
          endDate: today,
          age: null,
          gender: null,
          country: null,
          provinceIds: [],
          data: [],
          chartName: `chart ${generateRandomNumber()}`,
        },
      },
    });
    setIsLoading(false);
  };

  // method to delete selected chart
  const deleteChart = (index) => {
    const array = [...chartsData];
    array.splice(index, 1);

    dispatch({
      type: "setChartIndex",
      payload: 0,
    });
    dispatch({
      type: "setChartsCount",
      payload: chartsCount - 1,
    });
    dispatch({
      type: "setChartsData",
      payload: array,
    });

    delete dataByChartIndex[index];
    const updatedIndexData = Object.keys(dataByChartIndex).reduce((e, t, i) => {
      e[i] = dataByChartIndex[t];
      return e;
    }, {});

    dispatch({
      type: "setDataByChartIndex",
      payload: updatedIndexData,
    });
  };

  // method for tooltip customization
  const CustomTooltip = ({ active, payload }) => {
    if (!active || !payload) return null;
    const data = payload[0]?.payload ?? {};
    const keys = data && Object.keys(data).filter((item) => item !== "date");
    const names =
      payload && Array.from(new Set(payload.flatMap((item) => item.name)));
    keys.sort((a, b) => {
      const numA = parseInt(a.split("-")[1]);
      const numB = parseInt(b.split("-")[1]);
      return numA - numB;
    });

    return (
      <div
        key={data?.date}
        style={{
          padding: "6px",
          backgroundColor: "white",
          border: "1px solid grey",
        }}
      >
        <b>{data?.date}</b>
        <div style={{ display: "flex" }}>
          <div className="repeat-tt-main">
            {keys?.map((element, index) => {
              return (
                <>
                  <div className="repeat-tooltip">
                    <b>{names[index]}</b>
                    <p>New active users: {data[element].new}</p>
                    <p>Returning active users: {data[element].return}</p>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {Array.from({ length: chartsCount }, (_, index) => (
        <div key={index} className={chartIndex === index ? "" : "d-none"}>
          <Chart {...{ state, dispatch }} />
        </div>
      ))}

      <div className="row">
        <div className="col-lg-12">
          <div style={{ position: "relative" }}>
            <div className="d-flex align-items-center flex-wrap">
              <h2 className="head-sm">Chart Settings</h2>
              <div
                style={{
                  position: "relative",
                  float: "left",
                  margin: "3px",
                }}
              >
                <Button
                  disabled={
                    Object.keys(dataByChartIndex)?.length >= 10 || isLoading
                  }
                  onClick={() => addChart()}
                  className="ms-2"
                  appearance="link"
                >
                  <i className="fa fa-plus me-2"></i>
                  Add New Label
                </Button>
              </div>
            </div>
            {Array.from(
              { length: chartsCount },
              (_, index) =>
                dataByChartIndex[index]?.chartName !== undefined && (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      float: "left",
                    }}
                    className="btn-wrap-rm me-2 mb-2"
                  >
                    <Button
                      onClick={() => {
                        dispatch({
                          type: "setChartIndex",
                          payload: index,
                        });
                      }}
                      style={{
                        color: "black",
                      }}
                      appearance="ghost"
                      className={classNames(
                        {
                          "theme-btn alter active": chartIndex === index,
                        },
                        "faded theme-btn alter",
                      )}
                    >
                      {dataByChartIndex[index].chartName}
                    </Button>
                    {chartsCount > 1 && (
                      <Button
                        color="red"
                        className="cross-icon"
                        appearance="primary"
                        size="xs"
                        onClick={() => deleteChart(index)}
                      >
                        x
                      </Button>
                    )}
                  </div>
                ),
            )}
          </div>
        </div>
        {Array.from({ length: chartsCount }, (_, index) => (
          <div key={index} className={chartIndex === index ? "" : "d-none"}>
            <ChartSettings
              chartName={chartName}
              updateChartName={(name) => updateChartName(index, name)}
            />
          </div>
        ))}
      </div>

      <div className="row">
        <div className="col-lg-8">
          <div
            className="justify-content-center align-items-center p-5 mb-5 overflow-auto"
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            {formattedData.length ? (
              <ResponsiveContainer width="100%" height={400}>
                {loading ? (
                  <Loader center size="lg" content="loading" />
                ) : (
                  <BarChart data={formattedData} width={600} height={300}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip content={CustomTooltip} />
                    <XAxis
                      interval={0}
                      angle={-45}
                      textAnchor="end"
                      dataKey="date"
                      label={{
                        value: "Dates",
                        position: "bottom",
                        offset: "60",
                      }}
                    />
                    <YAxis
                      allowDecimals={false}
                      label={{
                        value: "No. of active users",
                        angle: -90,
                        position: "left",
                        offset: "-3",
                      }}
                    />
                    {Array.from({ length: chartsCount }, (_, index) => {
                      const key = `chart-${index + 1}`;
                      formattedData?.forEach((item) => {
                        if (!item[key]) {
                          item[key] = { new: 0, return: 0 };
                        }
                      });
                      return (
                        <>
                          <Bar
                            key={key}
                            dataKey={(data) => data[key]?.["new"]}
                            stackId={key}
                            fill={"#198754"}
                            name={dataByChartIndex[index]?.chartName ?? ""}
                          />
                          <Bar
                            key={`${key}-${index + 1}`}
                            dataKey={(data) => data[key]?.["return"]}
                            stackId={key}
                            fill={"#fffff"}
                            name={dataByChartIndex[index]?.chartName ?? ""}
                          />
                        </>
                      );
                    })}
                    <Legend
                      verticalAlign="top"
                      height={40}
                      payload={[
                        {
                          value: "New active users",
                          id: "new",
                          color: "#198754",
                          iconType: "square",
                        },
                        {
                          value: "Returning active users",
                          id: "return",
                          color: "#fffff",
                          iconType: "square",
                        },
                      ]}
                    />
                  </BarChart>
                )}
              </ResponsiveContainer>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>

        <div className="col-lg-4">
          {!!data?.summary?.new || !!data?.summary?.return ? (
            <div className="border rounded py-3 inactive-chart">
              <div className="d-flex align-items-center ps-3">
                <h3 className="head-sm text-dark">Students Engagement %</h3>
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={
                    <RTooltip>
                      <br />
                      <b>How it`s calculated ?</b>
                      <br />
                      <br />
                      A new student is someone whose last activity within the
                      selected duration is on the same day as their day of
                      registration.
                      <br />
                      <br />
                      A return student is someone whose last activity within the
                      selected duration is atleast a day after their day of
                      registration.
                      <br />
                      <br />
                      The green pie represents percentage of new students, and
                      the black represents return students against total
                      registrations so far. The percentage in the center of the
                      pie represents all active students, new and return
                      combined.
                      <br />
                      <br />
                    </RTooltip>
                  }
                >
                  <i className="fa-solid fa-circle-info light-txt ms-2"></i>
                </Whisper>
              </div>

              <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                  <Pie
                    data={piChart.graph}
                    isAnimationActive={true}
                    label={true}
                    nameKey="name"
                    dataKey="users"
                    labelLine
                    cx="50%"
                    cy="50%"
                    innerRadius={95}
                    outerRadius={150}
                    fill="#8884d8"
                  >
                    {piChart.graph.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                    <Label
                      value={piChart.percentage}
                      position="center"
                      className="label-top f-med total-label count"
                      fontSize="35px"
                    />
                  </Pie>
                  <Tooltip />
                  <Legend
                    iconType="square"
                    formatter={(value) => (
                      <span className="grey-txt f-14">{value}</span>
                    )}
                  />
                </PieChart>
              </ResponsiveContainer>
              <div className="text-dark text-center f-16 mt-2 ">
                Total Registrations : {data?.summary?.total}
              </div>
            </div>
          ) : (
            <NoDataPieChart />
          )}
        </div>
      </div>
    </Fragment>
  );
};

const payload = ({
  startDate,
  endDate,
  age,
  gender,
  country,
  provinceIds,
}) => ({
  start_date: format_start_of_day_in_time_zone(startDate),
  end_date: format_end_of_day_in_time_zone(endDate),
  age,
  gender,
  country,
  country_state: country == "Afghanistan" ? provinceIds : "",
});
export default StudentEngagement;
