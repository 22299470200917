import React, { Fragment } from "react";
import Filter from "./filter";

const Chart = ({ state, dispatch }) => {
  return (
    <Fragment>
      <div className="d-flex justify-content-end mb-3">
        <a className="theme-btn" href={"/analytics/students_engagement"}>
          <i className="fa fa-download me-2"></i>
          Download as XLSX
        </a>
      </div>
      <Filter {...{ state, dispatch }} />
    </Fragment>
  );
};

export default Chart;
