import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, ButtonGroup, Form, Pagination, SelectPicker } from "rsuite";
import useSWR from "swr";
import axios from "axios";

// components
import EditForm from "./form";
import GridView from "./components/Grid";
import TableView from "./components/Table";
import PageTitle from "../../components/page_title";
import DeleteConfirmationModal from "../../components/delete_confirmation_modal";

// helpers
import { isAdmin } from "../../utils/state";
import { fetcher } from "../../utils/fetcher";
import { initialState, reducer } from "./reducer";
import { ordinalize } from "../../utils/ordinalize";
import { getAuthenticityToken } from "../../utils/authenticity_token";
import { newCourse } from "./utils";

// css
import "./list.css";

const List = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { page, limit, layoutType } = state;
  const setPage = (page) => {
    dispatch({ type: "setPage", payload: page });

    if (searchParams.get("page")) {
      let url = "/courses/";
      if (searchParams.get("type")) {
        url = `${url}?type=${searchParams.get("type")}`;
      }
      if (searchParams.get("page")) {
        url = `${url}${url.includes("?") ? "&" : "?"}page=${page}`;
      }
      navigate(url);
    }
  };
  const setLimit = (limit) => dispatch({ type: "setLimit", payload: limit });
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [courseToEdit, setCourseToEdit] = useState(null);
  const [editErrors, setEditErrors] = useState({});

  const { data, isLoading, mutate } = useSWR(
    {
      url: "/api/v0/courses",
      params: payload({ ...state }),
    },
    fetcher,
  );

  useEffect(() => {
    handleLayoutType(searchParams.get("type"));
    handlePage(searchParams.get("page"));
  }, [searchParams.get("type"), searchParams.get("page")]);

  const handleLayoutType = (type) => {
    if (!type) return;
    dispatch({
      type: "setLayoutType",
      payload: type === "list" ? "List" : "Grid",
    });
  };

  const handlePage = (page) => {
    if (!page) return;
    dispatch({ type: "setPage", payload: parseInt(page) });
  };

  const startIndex = (page - 1) * limit;
  const endIndex = Math.min(startIndex + limit, data?.total);
  return (
    <div>
      {courseToEdit ? (
        <EditForm
          open={!!courseToEdit}
          onClose={() => {
            setCourseToEdit(null);
            setEditErrors(null);
          }}
          onConfirm={(data) => {
            const { id } = courseToEdit;
            const url = id ? `/api/v0/courses/${id}` : "/api/v0/courses";
            const method = id ? "PUT" : "POST";
            axios({
              method,
              url,
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getAuthenticityToken(),
              },
              data,
            })
              .then(() => {
                setEditErrors({});
                setCourseToEdit(null);
                mutate();
                return;
              })
              .catch((err) => {
                setEditErrors(err.response.data.errors);
              });
          }}
          course={courseToEdit}
          errors={editErrors}
        />
      ) : (
        <>
          <div className="container">
            <div className="mb-3">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <PageTitle title="Courses" />
                </div>
                <div className="col-md-8">
                  <div className="d-flex justify-content-end">
                    <Button
                      onClick={() => setCourseToEdit(newCourse())}
                      color="green"
                      appearance="primary"
                    >
                      <i className="fa fa-plus me-2"></i>New Course
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="shadow-sm bg-white radius-5">
              <div className="top bb px-3 pt-3 pb-2">
                <Filters {...{ state, dispatch }} />
              </div>

              <div className="inner">
                {layoutType === "Grid" ? (
                  <GridView
                    courses={data?.data ?? []}
                    page={page}
                    handleEdit={(course) => setCourseToEdit(course)}
                    handleDelete={(course) => setCourseToDelete(course)}
                  />
                ) : (
                  <TableView
                    isLoading={isLoading}
                    page={page}
                    limit={limit}
                    courses={data?.data ?? []}
                    handleEdit={(course) => setCourseToEdit(course)}
                    handleDelete={(course) => setCourseToDelete(course)}
                  />
                )}
              </div>
            </div>

            {((data?.data || []).length >= 10 || page > 1) && (
              <div className="d-flex justify-content-between align-items-center">
                <div className="grey-txt mt-2 f-14">
                  {startIndex + 1} to{" "}
                  {endIndex && endIndex != 0 ? endIndex : ""} of {data?.total}
                </div>

                <div className="pagination-outer">
                  <Pagination
                    prev
                    next={!isLoading}
                    ellipsis
                    layout={["limit", "|", "pager"]}
                    size="sm"
                    activePage={page}
                    onChangePage={setPage}
                    onChangeLimit={setLimit}
                    limit={limit}
                    limitOptions={[10, 30, 50]}
                    total={data?.total}
                    maxButtons={10}
                  />
                </div>
              </div>
            )}

            <DeleteConfirmationModal
              open={!!courseToDelete}
              onClose={() => setCourseToDelete(null)}
              onConfirm={() => {
                axios({
                  method: "DELETE",
                  url: `/api/v0/courses/${courseToDelete["id"]}`,
                  headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": getAuthenticityToken(),
                  },
                })
                  .then(() => {
                    mutate();
                    setCourseToDelete(null);
                    return;
                  })
                  .catch((error) => console.log(error));
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

const Filters = ({ state, dispatch }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { data: teachers } = useSWR("/api/v0/users", fetcher);
  const { data: languages } = useSWR("/api/v0/languages", fetcher);
  const { data: grades } = useSWR("/api/v0/grades", fetcher);
  return (
    <Form layout="inline" className="mb-0 text-end">
      {isAdmin() && (
        <Form.Group className="mb-2">
          <Form.Control
            name="teacher"
            accepter={SelectPicker}
            data={(teachers?.data || []).map((teacher) => ({
              label: teacher.attributes.email,
              value: teacher.id,
            }))}
            labelKey="label"
            valueKey="value"
            onChange={(value) =>
              dispatch({ type: "setTeacherId", payload: value })
            }
            value={state.teacherId}
            placeholder="Select Teacher"
          />
        </Form.Group>
      )}
      <Form.Group className="mb-2">
        <Form.Control
          name="language"
          accepter={SelectPicker}
          data={(languages?.data || []).map((language) => ({
            label: language.attributes.name,
            value: language.id,
          }))}
          labelKey="label"
          valueKey="value"
          onChange={(value) =>
            dispatch({ type: "setLanguageId", payload: value })
          }
          value={state.languageId}
          placeholder="Select Language"
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Control
          name="grade"
          accepter={SelectPicker}
          data={[
            ...(grades?.data || []).map((grade) => ({
              label: ordinalize(grade.attributes.name),
              value: grade.id,
            })),
          ]}
          labelKey="label"
          valueKey="value"
          onChange={(value) => dispatch({ type: "setGradeId", payload: value })}
          value={state.gradeId}
          placeholder="Select Course Grade"
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Control
          name="published"
          accepter={SelectPicker}
          data={[
            { label: "Published", value: "true" },
            { label: "Unpublished", value: "false" },
          ]}
          labelKey="label"
          valueKey="value"
          onChange={(value) =>
            dispatch({ type: "setPublished", payload: value })
          }
          value={state.published}
          defaultValue={null}
          searchable={false}
          placeholder="Select Status"
        />
      </Form.Group>
      <ButtonGroup className="my-tab-group">
        {["fa-solid fa-list-ul", "fa-solid fa-grip"].map((key) => (
          <Button
            key={key}
            active={
              (key === "fa-solid fa-list-ul" && state.layoutType === "List") ||
              (key === "fa-solid fa-grip" && state.layoutType === "Grid")
            }
            onClick={() => {
              dispatch({
                type: "setLayoutType",
                payload: key === "fa-solid fa-list-ul" ? "List" : "Grid",
              });

              let url = "/courses/";
              if (searchParams.get("type")) {
                url = `${url}?type=${key === "fa-solid fa-list-ul" ? "list" : "grid"}`;
              }
              if (searchParams.get("page")) {
                url = `${url}${url.includes("?") ? "&" : "?"}page=${searchParams.get("page")}`;
              }
              navigate(url);
            }}
          >
            <i className={key}></i>
          </Button>
        ))}
      </ButtonGroup>
    </Form>
  );
};

const payload = ({
  teacherId,
  languageId,
  gradeId,
  schoolLevelId,
  published,
  page,
  limit,
}) => ({
  teacher_id: teacherId,
  language_id: languageId,
  grade_id: gradeId || schoolLevelId,
  published,
  page,
  limit,
});

export default List;
