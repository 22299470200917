import React from "react";
import { Button, Modal } from "rsuite";

const ConfirmModal = ({ open, onClose, onConfirm,
   message = "Do you really want to perform this action? Please confirm to proceed." }) => (
  <Modal open={open} onClose={onClose} size="xs" backdrop="static">
    <Modal.Header>
      <Modal.Title>
        Are you sure?
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p className="grey-txt">{message}</p> 
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onClose} appearance="subtle">
        Cancel
      </Button>
      <Button onClick={onConfirm} color="green" appearance="primary">
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ConfirmModal;
