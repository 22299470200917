import React, { useState, useReducer, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Form, SelectPicker, Table, Pagination, Toggle } from "rsuite";
const { Column, Cell: OriginalCell, HeaderCell: OriginalHeaderCell } = Table;
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";
import axios from "axios";
import { getAuthenticityToken } from "../../utils/authenticity_token";
import ConfirmModal from "../../components/confirm_modal";
import { replaceEmptyValues, textCapitalize } from "../../utils/helpers";
import { format_date_without_time_zone } from "../../utils/date_formatter";

const HeaderCell = (props) => (
  <OriginalHeaderCell {...props} style={{ padding: 10 }} />
);

const Cell = (propos) => <OriginalCell {...propos} style={{ padding: 10 }} />;

const initialState = {
  page: 1,
  limit: 10,
  status: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setPage":
      return { ...state, page: action.payload };
    case "setLimit":
      return { ...state, limit: action.payload, page: 1 };
    case "setStatus":
      return { ...state, status: action.payload, page: 1 };
    default:
      return state;
  }
};

const Filters = ({ state, dispatch, type }) => {
  useEffect(() => {
    dispatch({ type: "setStatus", payload: "" });
  }, [dispatch, type]);

  return (
    <Form layout="inline" className="mb-0">
      <Form.Group className="mb-2">
        <Form.Control
          name="language"
          accepter={SelectPicker}
          data={[
            ...(type == "state"
              ? [
                  {
                    value: "active",
                    label: "Active",
                  },
                ]
              : []),
            ...(type != "state"
              ? [
                  {
                    value: "scheduled",
                    label: "Scheduled",
                  },
                  {
                    value: "ended",
                    label: "Ended",
                  },
                ]
              : []),
            {
              value: "disabled",
              label: "Disabled",
            },
          ]}
          labelKey="label"
          valueKey="value"
          onChange={(value) => dispatch({ type: "setStatus", payload: value })}
          value={state.status}
          placeholder="Select Status"
          searchable={false}
        />
      </Form.Group>
    </Form>
  );
};

const List = ({ type, setPageName, setReminderToEdit }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { page, limit } = state;

  const setPage = (page) => dispatch({ type: "setPage", payload: page });
  const setLimit = (limit) => dispatch({ type: "setLimit", payload: limit });

  const [enableReminder, setEnableReminder] = useState(null);

  const payload = ({ status }) => ({
    type,
    status,
    page,
    limit,
  });

  const {
    data: reminders,
    isLoading,
    mutate,
  } = useSWR(
    {
      url: "/api/v0/reminders",
      params: payload({ ...state }),
    },
    fetcher,
  );

  useEffect(() => {
    setPageName(type);
  }, [type]);

  useEffect(() => {
    if (!reminders?.data) return;
    replaceEmptyValues(reminders?.data);
  }, [reminders?.data]);

  useEffect(() => {
    if (parseInt(searchParams.get("page")) > 1) {
      dispatch({
        type: "setPage",
        payload: parseInt(searchParams.get("page")),
      });
    }
  }, [searchParams.get("page")]);

  const startIndex = (page - 1) * limit;
  const endIndex = Math.min(startIndex + limit, reminders?.total);

  return (
    <>
      <div className="shadow-sm bg-white radius-5">
        <div className="top bb px-3 pt-3 pb-2">
          <Filters {...{ state, dispatch, type }} />
        </div>
        <div className="inner">
          <Table
            loading={isLoading}
            height={500}
            width={"100wv"}
            data={reminders?.data || []}
            className="text-capitalize"
          >
            <Column width={100} flexGrow={3}>
              <HeaderCell>Title</HeaderCell>
              <Cell>
                {(rowData) => (
                  <span title={textCapitalize(rowData?.attributes?.title)}>
                    {rowData?.attributes?.title}
                  </span>
                )}
              </Cell>
            </Column>
            <Column width={100} flexGrow={3}>
              <HeaderCell>Message</HeaderCell>
              <Cell>
                {(rowData) => (
                  <span title={textCapitalize(rowData?.attributes?.message)}>
                    {rowData?.attributes?.message}
                  </span>
                )}
              </Cell>
            </Column>
            {type == "state" && (
              <Column width={100} flexGrow={2}>
                <HeaderCell>State</HeaderCell>
                <Cell dataKey="attributes.state" />
              </Column>
            )}
            {type != "state" && (
              <Column width={100} flexGrow={2}>
                <HeaderCell>Created At</HeaderCell>
                <Cell>
                  {(rowData) => {
                    let date = new Date(rowData?.attributes?.created_at);
                    return <span>{format_date_without_time_zone(date)}</span>;
                  }}
                </Cell>
              </Column>
            )}
            {type != "state" && (
              <Column width={100} flexGrow={2}>
                <HeaderCell>Last Published At</HeaderCell>
                <Cell dataKey="attributes.last_published_at" />
              </Column>
            )}
            <Column width={100} flexGrow={2}>
              <HeaderCell>Occurance</HeaderCell>
              <Cell>
                {(rowData) => {
                  return rowData?.attributes?.type == "once"
                    ? "One Time"
                    : rowData?.attributes?.type == "repeat"
                      ? "Repeated"
                      : rowData?.attributes?.type == "state"
                        ? "State Based"
                        : "n/a";
                }}
              </Cell>
            </Column>
            {type != "state" && (
              <Column width={100} flexGrow={2}>
                <HeaderCell>Ending On</HeaderCell>
                <Cell>
                  {(rowData) => {
                    return rowData?.attributes?.end_date == "--"
                      ? "Never Ends"
                      : rowData?.attributes?.end_date;
                  }}
                </Cell>
              </Column>
            )}
            <Column width={100} flexGrow={2}>
              <HeaderCell>Status</HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <div style={{ marginTop: -6 }}>
                      <span>{rowData?.attributes?.status}</span>
                      <br />
                      {rowData?.attributes?.status == "scheduled" && (
                        <span>{rowData?.attributes?.next_scheduled_on}</span>
                      )}
                    </div>
                  );
                }}
              </Cell>
            </Column>
            <Column width={100} flexGrow={2}>
              <HeaderCell>Media Type</HeaderCell>
              <Cell>
                {(rowData) => rowData?.attributes?.media_type || "--"}
              </Cell>
            </Column>
            {type == "state" && (
              <Column width={100} flexGrow={2}>
                <HeaderCell>Last Modified At</HeaderCell>
                <Cell>
                  {(rowData) => {
                    let date = new Date(rowData?.attributes?.updated_at);
                    return <span>{format_date_without_time_zone(date)}</span>;
                  }}
                </Cell>
              </Column>
            )}
            <Column
              width={150}
              fixed="right"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <HeaderCell>Actions</HeaderCell>
              <Cell style={{ padding: "6px" }}>
                {(rowData) => {
                  return (
                    <>
                      <Toggle
                        size="sm"
                        color={rowData?.attributes?.enabled ? "green" : "red"}
                        className="me-2"
                        checked={rowData?.attributes?.enabled}
                        appearance="ghost"
                        onChange={() => setEnableReminder(rowData)}
                      />
                      <i
                        className="fa-solid fa-pencil me-2"
                        title="Edit"
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {
                          if (page > 1) rowData.page = page;
                          setReminderToEdit(rowData);
                        }}
                        onClick={() => {
                          if (page > 1) rowData.page = page;
                          setReminderToEdit(rowData);
                        }}
                      ></i>
                      <i
                        className="fa-solid fa-user me-2"
                        title="Students"
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {
                          window.location.href = `/api/v0/reminders/${rowData.id}/students`;
                        }}
                        onClick={() => {
                          window.location.href = `/api/v0/reminders/${rowData.id}/students`;
                        }}
                      ></i>
                    </>
                  );
                }}
              </Cell>
            </Column>
          </Table>
        </div>
      </div>
      {enableReminder && (
        <ConfirmModal
          open={!!enableReminder}
          onClose={() => setEnableReminder(null)}
          onConfirm={() => {
            axios({
              method: "patch",
              url: `/api/v0/reminders/${enableReminder.id}`,
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getAuthenticityToken(),
              },
              data: { enabled: !enableReminder?.attributes?.enabled },
            })
              .then(() => {
                setEnableReminder(null);
                mutate();
                return;
              })
              .catch((err) => {
                console.log(err.response.data.errors);
              });
          }}
        />
      )}
      {((reminders?.data || []).length >= 10 || page > 1) && (
        <div className="d-flex justify-content-between align-items-center">
          <div className="grey-txt mt-2 f-14">
            {startIndex + 1} to {endIndex && endIndex != 0 ? endIndex : ""} of{" "}
            {reminders?.total}
          </div>

          <div className="pagination-outer">
            <Pagination
              prev
              next={!isLoading}
              ellipsis
              layout={["limit", "|", "pager"]}
              size="sm"
              activePage={page}
              onChangePage={(value) => {
                setPage(value);
                navigate(location.pathname);
              }}
              onChangeLimit={setLimit}
              limit={limit}
              limitOptions={[10, 30, 50]}
              total={reminders?.total}
              maxButtons={10}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default List;
