export const initialState = {
  page: 1,
  limit: 10,
  languageId: null,
  gradeId: null,
  schoolLevelId: null,
  published: null,
  teacherId: null,
  layoutType: "Grid",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "setPage":
      return { ...state, page: action.payload };
    case "setLimit":
      return { ...state, limit: action.payload, page: 1 };
    case "setTeacherId":
      return { ...state, teacherId: action.payload, page: 1 };
    case "setLanguageId":
      return { ...state, languageId: action.payload, page: 1 };
    case "setGradeId":
      return { ...state, gradeId: action.payload, schoolLevelId: "", page: 1 };
    case "setSchoolLevelId":
      return { ...state, schoolLevelId: action.payload, gradeId: "", page: 1 };
    case "setPublished":
      return { ...state, published: action.payload, page: 1 };
    case "setLayoutType":
      return { ...state, layoutType: action.payload };
    default:
      return state;
  }
};
