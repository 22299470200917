import React from "react";

// ReCharts
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import NoDataFound from "../../../components/bar_chart/noDataFound";

const LocationBarChart = ({
  isProvince,
  hasData,
  countriesChartData,
  legendData,
  startDate,
  handleCountry,
}) => {
  if (!hasData) return <NoDataFound />;
  return (
    <ResponsiveContainer width="100%" height={280}>
      <BarChart
        width={730}
        margin={{
          top: 5,
          left: 0,
          bottom: 5,
          right: 0,
        }}
        data={countriesChartData ?? []}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={isProvince ? "province" : "country"}
          label={{
            value: isProvince ? "Provinces" : "Countries",
            position: "insideBottom",
            offset: "-112",
          }}
          tick={{ fontSize: 12 }}
          textAnchor="end"
          angle={-25}
          onClick={(country) =>
            country.value.includes("Afghanistan") &&
            handleCountry(country.value)
          }
        />
        <YAxis
          allowDecimals={false}
          label={{
            value: "Numbers",
            angle: -90,
            position: "left",
            offset: "-3",
          }}
        />
        <ReferenceLine y={0} stroke="#000" />
        <Brush
          dataKey={() => null} // remove text in brush component
          y={340}
          height={15}
          startIndex={0}
          endIndex={isProvince ? 5 : 10}
          stroke="#198754"
          padding={{ top: 55 }}
          margin={{ top: 55 }}
        />
        <Legend verticalAlign="top" height={34} iconType="square" />
        <Tooltip />
        <Bar
          name={`Registrations (${legendData?.registrationsCount || 0})`}
          dataKey="registrations"
          fill="#ee3737"
        />
        <Bar
          name={`Enrollments (${legendData?.enrollmentsCount || 0}) `}
          dataKey="enrollments"
          fill="#0d6d18"
        />
        <Bar
          name={`Certificates Issued (${legendData?.certificatesCount || 0}) `}
          dataKey="certificates"
          fill="#18181a"
        />
        <Bar
          name={`Lessons Requested (${legendData?.contentRequestedCount || 0}) `}
          dataKey="contentRequested"
          fill="#43C4C7"
        />
        {new Date(startDate) > new Date("2024-05-07") && (
          <Bar
            name={`Requested Lessons Completed (${legendData?.contentCompletedCount || 0}) `}
            dataKey="contentCompleted"
            fill="#FF8042"
          />
        )}
        <Bar
          name={`Total Lessons Completed (${legendData?.totalContentCompletedCount || 0}) `}
          dataKey="totalContentCompleted"
          fill="#B442FF"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default LocationBarChart;
