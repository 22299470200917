import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Protected from "./components/routes/proctected";
import Sidebar from "./components/sidebar";
import TopNavbar from "./components/top_navbar";
import Courses from "./pages/courses";
import Dashboard from "./pages/dashboard";
import Analytics from "./pages/analytics";
import Users from "./pages/users";
import ServiceNumber from "./pages/service_numbers";
import Guests from "./pages/guests";
import Certificate from "./pages/certificates";
import CertificateTemplates from "./pages/certificate_templates";
import Reminders from "./pages/reminders";
import PhoneNumbers from "./pages/phone_numbers";
import { isLoggedIn, isPhoneNumbersRemovingEnabled } from "./utils/state";
import CertificateAuthenticater from "./pages/certificate_templates/certificate_authenticater";
import "../../../assets/stylesheets/common.scss";
import ProjectMedia from "./pages/project_media";
import DemoVideos from "./pages/demo_videos";
import Students from "./pages/students";
import DemoVideoRequests from "./pages/demo_videos/requests";

const createRoutes = (list) =>
  list.map((item) => (
    <Route path={item.path} element={item.element} key={item.path} />
  ));

const RoutesList = () => (
  <Routes>
    {createRoutes([
      {
        path: "/guests/*",
        element: <Guests />,
      },
      {
        path: "/home/*",
        element: (
          <Protected adminProtected={true}>
            <Dashboard />
          </Protected>
        ),
      },
      {
        path: "/courses/*",
        element: (
          <Protected>
            <Courses />
          </Protected>
        ),
      },
      {
        path: "/students/*",
        element: (
          <Protected>
            <Students />
          </Protected>
        ),
      },
      {
        path: "/reminders/*",
        element: (
          <Protected adminProtected={true}>
            <Reminders />
          </Protected>
        ),
      },
      {
        path: "/analytics/*",
        element: (
          <Protected adminProtected={true}>
            <Analytics />
          </Protected>
        ),
      },
      {
        path: "/users/*",
        element: (
          <Protected adminProtected={true}>
            <Users />
          </Protected>
        ),
      },
      {
        path: "/service_numbers/*",
        element: (
          <Protected adminProtected={true}>
            <ServiceNumber />
          </Protected>
        ),
      },
      {
        path: "/project_media/*",
        element: (
          <Protected adminProtected={true}>
            <ProjectMedia />
          </Protected>
        ),
      },
      {
        path: "/certificates/*",
        element: (
          <Protected adminProtected={true}>
            <Certificate />
          </Protected>
        ),
      },
      {
        path: "/certificate_templates/*",
        element: (
          <Protected adminProtected={true}>
            <CertificateTemplates />
          </Protected>
        ),
      },
      {
        path: "/certificate_authenticator/*",
        element: <CertificateAuthenticater />,
      },
      ...(isPhoneNumbersRemovingEnabled()
        ? [
            {
              path: "/phone_numbers/*",
              element: (
                <Protected>
                  <PhoneNumbers />
                </Protected>
              ),
            },
          ]
        : []),
      {
        path: "/demo_videos/:id/requests",
        element: (
          <Protected adminProtected={true}>
            <DemoVideoRequests />
          </Protected>
        ),
      },
      {
        path: "/demo_videos/*",
        element: (
          <Protected adminProtected={true}>
            <DemoVideos />
          </Protected>
        ),
      },
      {
        path: "*",
        element: <Navigate to={"/home"} />,
      },
    ])}
  </Routes>
);

const Application = () => (
  <BrowserRouter>
    <div id="outer-container">
      {!isLoggedIn() ? (
        <RoutesList />
      ) : (
        <Fragment>
          <Sidebar />
          <div
            id={"page-wrap"}
            style={{ height: "100%" }}
            className="main-content"
          >
            <TopNavbar />
            <div className="content-inner py-3 px-md-3">
              <RoutesList />
            </div>
          </div>
        </Fragment>
      )}
    </div>
  </BrowserRouter>
);

export default Application;
